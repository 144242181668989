<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thêm mới tài khoản'">
          <template v-slot:preview>
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="col-md-12 mb-5">
                <div class="row my-3">
                  <div class="col-md-3">
                    <b-row class="my-1">
                      <label class="label-input">Tên tài khoản:</label>
                      <span class="text-danger">*</span>
                    </b-row>
                    <b-row class="my-1">
                      <b-input
                        id="input-small"
                        size="sm"
                        placeholder="Nhập tên tài khoản"
                        v-model="$v.form.username.$model"
                        :state="validateState('username')"
                        append-icon="search"
                        single-line
                        hide-details
                        class="col-md-12 form-control-sm"
                        aria-describedby="input-live-help input-live-feedback"
                      ></b-input>
                      <b-form-invalid-feedback v-if="!$v.form.username.required">
                        Vui lòng nhập tên tài khoản
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-if="!$v.form.username.minLength">
                        Vui lòng nhập tên tài khoản ít nhất {{ $v.form.username.$params.minLength.min }} ký tự
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-if="!$v.form.username.maxLength">
                        Vui lòng nhập tên tài khoản không quá {{ $v.form.username.$params.maxLength.max }} ký tự
                      </b-form-invalid-feedback>
                    </b-row>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <b-row class="my-1">
                      <label class="label-input">Mật khẩu:</label>
                      <span class="text-danger">*</span>
                    </b-row>
                    <b-row class="my-1">
                      <b-input
                        type="password"
                        id="inline-form-input-name"
                        placeholder="Nhập tên mật khẩu"
                        append-icon="search"
                        single-line
                        hide-details
                        class="col-md-12 form-control-sm"
                        v-model="$v.form.password.$model"
                        :state="validateState('password')"
                        aria-describedby="input-live-help input-live-feedback"
                      ></b-input>
                      <b-form-invalid-feedback v-if="!$v.form.password.required">
                        Vui lòng nhập mật khẩu
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-if="!$v.form.password.minLength">
                        Vui lòng nhập mật khẩu ít nhất {{ $v.form.password.$params.minLength.min }} ký tự
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-if="!$v.form.password.maxLength">
                        Vui lòng nhập mật khẩu không quá {{ $v.form.password.$params.maxLength.max }} ký tự
                      </b-form-invalid-feedback>
                    </b-row>
                  </div>
                  <div class="col-md-3">
                    <b-row class="my-1">
                      <b-col>
                        <label class="label-input">Nhân sự:
                          <span class="text-danger">*</span>
                        </label>
                      </b-col>
                    </b-row>
                    <Autosuggest
                      :model="form.searchEmployee"
                      :suggestions="filteredOptions"
                      :placeholder="'tên nhân sự'"
                      :limit="200"
                      @select="onSelectedEmployee"
                      @change="onInputChange"
                      :state="validateState('selectedEmployee')"
                      errorMessages="Hẫy chọn tên nhân sự"
                      suggestionName="name"
                    />
                  </div>
                  <div class="col-md-3">
                    <b-row class="my-1">
                      <b-col>
                        <label class="label-input">Quyền:
                          <span class="text-danger">*</span>
                        </label>
                      </b-col>
                    </b-row>
                    <b-row class="my-1">
                      <b-col>
                        <b-form-select
                          size="sm"
                          id="input-2"
                          v-model="$v.form.selectedRole.$model"
                          :state="validateState('selectedRole')"
                          :options="listRole"
                          required
                          value-field="id"
                          text-field="name"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="null" disabled
                              >Hãy chọn quyền</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                        <b-form-invalid-feedback v-if="!$v.form.selectedRole.required">
                          Hãy chọn quyền cho tài khoản
                        </b-form-invalid-feedback>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="col-md-3">
                    <b-row class="my-1">
                      <b-col>
                        <label class="label-input">Trạng thái:</label>
                      </b-col>
                    </b-row>
                    <b-row class="my-1">
                      <b-col>
                        <b-form-select
                          size="sm"
                          id="input-3"
                          v-model="selectedStatus"
                          :options="listStatus"
                          required
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                        ></b-form-select>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </v-form>
          </template>
          <template v-slot:foot>
            <!-- <b-col lg="4" class="pb-2"> -->
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="debounceCreateUser"
              >Lưu</b-button
            >
            <!-- </b-col> -->
            <!-- <b-col lg="2" class="pb-2"> -->
            <router-link to="/users" tag="button">
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
            <!-- </b-col> -->
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style>
input::-webkit-calendar-picker-indicator {
  display: none;
}

.label-input {
  font-weight: 500;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { validationMixin } from 'vuelidate';
import { minLength, required, maxLength } from 'vuelidate/lib/validators';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import ApiService from '@/core/services/api.service';
import { xoa_dau } from '@/utils/common';
import { TIME_TRIGGER } from '@/utils/constants';
import decounce from 'debounce';

export default {
  mixins: [validationMixin],
  data() {
    return {
      valid: true,
      form: {
        username: '',
        password: '',
        searchEmployee: '',
        selectedEmployee: null,
        selectedRole: null,
      },
      selectedStatus: 1,
      listStatus: [
        { id: 1, name: 'Hoạt động' },
        { id: 2, name: 'Khóa' },
      ],
      selectedRole: null,
      listRole: [],
      selectedId: null,
      isNew: true,
      filteredOptions: [],
      options: [],
    };
  },
  validations: {
    form: {
      username: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(15),
      },
      password: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(15),
      },
      selectedEmployee: { 
        required,
      },
      selectedRole: { 
        required,
      }
    },
  },
  components: {
    KTCodePreview,
    Autosuggest,
  },
  created() {
    this.fetchData();
    this.fetchDataEmployee();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Tài khoản', route: '/users' },
      { title: 'Danh sách tài khoản', route: '/users' },
      { title: 'Thêm mới tài khoản' },
    ]);
  },
  methods: {
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    debounceCreateUser: decounce(function () {
      this.createUser();
    }, TIME_TRIGGER),
    createUser: async function () {
      this.isNew = false;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const username = this.$v.form.username.$model;
      const password = this.$v.form.password.$model;
      const employeeId = this.$v.form.selectedEmployee.$model;
      const roleId = this.$v.form.selectedRole.$model;
      let isActive = false;
      if (this.selectedStatus === 1) {
        isActive = true;
      }
      let data = {
        username: username,
        password: password,
        employeeId: employeeId,
        roleId: roleId,
        isActive: isActive,
      };
      ApiService.setHeader();
      ApiService.post('user/create', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess('Thêm mới tài khoản thành công!');
            setTimeout(() => {
              this.$router.push({
                path: '/users',
              });
            }, 1000);
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    fetchData: async function () {
      this.listRole = [];
      let param = {
        page: 1,
        limit: 100,
      };
      let paramQuery = {
        params: param,
      };
      ApiService.query('role', paramQuery).then(({ data }) => {
        data.data.list_role.forEach((element) => {
          let item = {
            id: element.id,
            name: element.name,
          };
          this.listRole.push(item);
        });
      });
    },
    fetchDataEmployee: async function () {
      ApiService.query('employees/getAll')
        .then((response) => {
          const listEmployee = response.data.data;
          this.options = listEmployee.map((item) => {
            const nameNoneSign = xoa_dau(item.fullName);
            return {
              id: item.id,
              name: item.fullName,
              nameNoneSign: nameNoneSign,
            };
          });

          this.filteredOptions = [...this.options];
        })
        .catch(() => {});
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    onSelectedEmployee(option) {
      this.form.searchEmployee = option.item.name;
      this.form.selectedEmployee = option.item.id;
    },
    onInputChange(text) {
      if (text === '') {
        this.inputSearchProductProp.disabled = true;
      }
      if (this.form.searchEmployee === '' || this.form.searchEmployee.length - 1){ 
        this.form.selectedEmployee = null;
      }
      this.searchStore = text;
      const filteredData = this.options
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptions = [...filteredData];
    },
  },
};
</script>
